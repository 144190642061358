import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { useIntl } from "react-intl";
import dompurify from "dompurify";
import { valuesToTranslate } from "../../constants";
import { API, DataNode, DataSection, SECTION_ID } from "../../data/types";
import { isNodeDisabled } from "../../utils/isNodeDisabled";
import { debug } from "console";
import { specialRound } from "../../utils/specialRound";

const sanitizer = dompurify.sanitize;

const CustomFormSelect = styled(Select)`
  height: 40px;
  max-width: 146px;
  margin-bottom: 16px;
`;

const Progress = styled(CircularProgress)`
  position: relative;
  top: 4px;
  left: 6px;
`;

const CustomMenuItem = styled(MenuItem)`
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column;
`;

const debugFormula = (d: DataSection[], entry: DataNode) => {
  const insulationThickness = d
    .find((e) => e.id === SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER)
    ?.nodes.find(
      (n) => n.title === "Insulation layer thickness (mm):"
    )?.selectValue;
  if (!insulationThickness) {
    return "";
  }

  const insulationThicknessValue =
    parseFloat(insulationThickness.split(" - ")[1]) / 1000;
  const lambda = d
    .find((e) => e.id === SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER)
    ?.nodes.find((n) => n.title === "Lambda value λ(w/mK):")?.selectValue;
  if (!lambda || !insulationThickness) {
    return "";
  }
  const ri = insulationThicknessValue / parseFloat(lambda);

  const otherThickness = d
    .find((e) => e.api === API.PITCHED_ROOF_OUTSIDE_BARRIER)
    ?.nodes.find(
      (n) => n.title === "Insulation layer thickness (m):"
    )?.selectValue;
  if (!otherThickness) {
    return "";
  }

  return (
    <>
      <span>{`Ri: ${ri.toFixed(6)}`}</span>
      <br></br>
      <span>{`Re: ${otherThickness} / ${entry.title} = ${(
        parseFloat(otherThickness) / parseFloat(entry.title)
      ).toFixed(6)}`}</span>
      <br></br>
      <span>{`Re / Ri = ${(
        parseFloat(otherThickness) /
        parseFloat(entry.title) /
        ri
      ).toFixed(6)}`}</span>
      <br></br>
      <span
        style={{
          color: `${
            specialRound(
              parseFloat(otherThickness) / parseFloat(entry.title) / ri
            ) >= 0.8
              ? "green"
              : "red"
          }`,
        }}
      >{`Rounded result: ${specialRound(
        parseFloat(otherThickness) / parseFloat(entry.title) / ri
      )}`}</span>
    </>
  );
};

export interface SelectComponentProps {
  title: string;
  value: string;
  id: string;
  childNodes?: DataNode[];
  onSelect: (id: string, value: string) => void;
  isLoading: boolean;
  disabled: boolean;
  allData: DataSection[];
  shouldShowDisabledMessage?: boolean;
  api?: API;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  title,
  id,
  childNodes,
  value,
  onSelect,
  isLoading,
  disabled,
  allData,
  api,
  shouldShowDisabledMessage = false,
}) => {
  const intl = useIntl();
  return (
    <FormControl fullWidth>
      <FormLabel id={`select-${id}`} disabled={isLoading || disabled}>
        <p
          style={{ margin: 0 }}
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              intl.formatMessage({ id: title, defaultMessage: title })
            ),
          }}
        ></p>
        {isLoading && <Progress color="primary" size={16} />}
      </FormLabel>
      <CustomFormSelect
        id={`select-${id}`}
        value={value ?? ""}
        labelId={`select-${id}`}
        disabled={isLoading || disabled}
        onChange={(e: any) => {
          onSelect(id, e.target.value as string);
        }}
      >
        {childNodes?.map((entry, num) => {
          const disabled = isNodeDisabled(entry, allData);
          return (
            <CustomMenuItem
              key={num}
              value={entry.id}
              disabled={entry.disabled || disabled}
            >
              {valuesToTranslate.includes(entry.title)
                ? intl.formatMessage({
                    id: entry.title,
                    defaultMessage: entry.title,
                  })
                : entry.title}
              {/* {api === API.PITCHED_ROOF_OUTSIDE_BARRIER &&
                id === "pr_b_10_2" && (
                  <div style={{ fontSize: "12px" }}>
                    {debugFormula(allData, entry)}
                  </div>
                )} */}
              {(entry.disabled || disabled) && shouldShowDisabledMessage && (
                <span style={{ fontSize: "12px" }}>
                  {intl.formatMessage({
                    id: "This construction is not physically possible",
                    defaultMessage:
                      "This construction is not physically possible",
                  })}
                </span>
              )}
            </CustomMenuItem>
          );
        })}
      </CustomFormSelect>
    </FormControl>
  );
};

export default SelectComponent;
