import { KeyboardArrowLeft } from "@mui/icons-material";
import { Button, styled as MuiStyled } from "@mui/material";
import styled from "styled-components";
import { Title } from "../Title";
import { TitleRow } from "../TitleRow";
import { useAppSelector } from "../../hooks";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/logo.svg";
import usePassword from "../../hooks/usePassword";
import { FormattedMessage, useIntl } from "react-intl";
import { fetchTerms } from "../../api";
import { ForwardArrow } from "../ForwardArrow";
import dompurify from "dompurify";
import CircularProgress from "@mui/material/CircularProgress";
import {
  addCol,
  findProduct,
  isNumeric,
  nodeValue,
  redText,
  sanitizeWarningMessage,
  showCheckedRadio,
} from "../../utils";
import submitForm from "../../api/submitForm";
import { useDispatch } from "react-redux";
import {
  ErrorType,
  RoofType,
  pdfText3Flat,
  pdfText3Pitched,
  setFormSubmitError,
  setFormSubmitting,
  setPdfFormDataToSend,
  setformSubmitted,
} from "../../reducers/appData";
import { HowToUseButton, WarningContainer } from "../../styledComponents";
import { useBreadcrumbs } from "../../data/breadcrumbs";
import { BreadcrumbsContainer } from "../Breadcrumbs";
import scrollFunction from "../../utils/scrollFunction";
import { letterOrder } from "../../data/appData";
import { changeAddress } from "../../utils/changeAddress";
import { API, DataNode, SECTION_ID } from "../../data/types";
import {
  letterSignature,
  pdfText1,
  pdfText2,
  statementMsg,
  termsAndConditionsTitle,
} from "../../data/constants";
import { pitchedRoofOpenMajcoatSections } from "../../data/pitchedRoof/outside/majcoat";
import { textProductReplacement } from "../../utils/textProductReplacement";
import dayjsExtended from "../../utils/dayjsExtended";

const sanitizer = dompurify.sanitize;

const TermsAndConditionsWrapper = styled.div`
  width: calc() (100% - 32px);
  padding-left: 16px;
  padding-right: 16px;
`;

const TermsAndConditionsArea = styled.div`
  width: calc(100% - 160px);
  height: calc(100vh - 600px);
  border: 1px solid #e0e0e0;
  box-shadow: 1px 7px 24px -13px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  overflow-y: auto;
  padding: 60px 80px;
  h2 {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
  }
  p {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const BackButton = MuiStyled(Button)`
    color: rgba(0,0,0,0.4);
    text-transform: initial;
`;

const PrintLogoArea = styled.div`
  display: none;
  @media print {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
  }
`;
const LogoImage = styled.img`
  height: 34px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  justify-content: space-between;
`;

const FooterArea = styled.div`
  margin-bottom: 64px;
  .warning-message {
    margin-top: 16px;
    max-width: 520px;
  }
`;

const AcceptButton = styled(Button)`
  width: 100%;
  max-width: 312px;
  border-radius: 40px !important;
  text-transform: initial !important;
  font-weight: 300 !important;
  height: 45px;
`;

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const hasPassword = usePassword();
  const breadcrumbs = useBreadcrumbs();
  let componentRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState("");
  const [scrolledDown, setScrolledDown] = useState(false);
  const checkedRadios = useAppSelector((state) => state.appData.checkedRadios);
  const formSubmitting = useAppSelector(
    (state) => state.appData.formSubmitting
  );
  const formSubmitted = useAppSelector((state) => state.appData.formSubmitted);
  const formSubmitError = useAppSelector(
    (state) => state.appData.formSubmitError
  );
  const { lang } = useParams();
  const termsRef = useRef<HTMLDivElement>(null);
  const formData = useAppSelector((state) => state.appData.formData);
  const data = useAppSelector((state) => state.appData.data);
  const formTimeStamp = useAppSelector((state) => state.appData.formTimestamp);

  const dayjsObject = dayjsExtended(lang as string);
  dayjsObject.locale(lang);

  const roofType: RoofType =
    data.find((e) => !!e.api)?.api === API.FLAT_ROOF
      ? RoofType.FLAT
      : RoofType.PITCHED;

  const product = findProduct({ data, roofType, intl });

  const isSwitzerland =
    formData.fields.find((f) => f.id === "form_country")?.value === "CH";

  useEffect(() => {
    const fetchTermsFromApi = async () => {
      const terms = await fetchTerms(isSwitzerland ? "ch" : lang || "en");
      setHtmlContent(terms.data);
    };
    fetchTermsFromApi();
  }, []);

  useEffect(() => {
    if (componentRef?.current) {
      scrollFunction();
    }
  }, [componentRef]);

  const surveyCompleted = useAppSelector(
    (state) => state.appData.lastLevelReached
  );

  useEffect(() => {
    if (!surveyCompleted) {
      navigate(`/${lang}`, { replace: true });
    }
  }, [surveyCompleted, hasPassword, navigate, lang]);

  const handleScroll = () => {
    const div = termsRef.current;
    if (div && div.scrollTop + div.clientHeight >= div.scrollHeight - 5) {
      setScrolledDown(true);
    } else {
      setScrolledDown(false);
    }
  };

  const goToNextStep = async () => {
    dispatch(setFormSubmitError(undefined));
    // if (formSubmitted) {
    //   navigate(`/${lang}/success`);
    //   return;
    // }
    let headerAnswerCount = 4;
    const answers: {
      [key: string]: {
        q: string;
        a: string;
        w?: string;
        w_show?: boolean;
      }[];
    } = {};
    letterOrder[data.find((e) => !!e.api)?.api || API.FLAT_ROOF].forEach(
      (key, i) => {
        if (!data.find((e) => e.id === key)) return;
        const level = data.findIndex((d) => d.id === key);
        const levelData = data[level];
        const selectedNode = levelData.nodes.find((n) => n.selected);
        const translatedNodeValue = (node: DataNode) =>
          isNumeric(nodeValue(node).value)
            ? nodeValue(node).value
            : `${intl.formatMessage({
                id: nodeValue(node).value,
                defaultMessage: nodeValue(node).value,
              })} ${showCheckedRadio(checkedRadios, node.id, level)}`;
        if (!levelData.mustSelectAll && selectedNode && levelData.title) {
          answers[key] = [
            {
              q: addCol(
                intl.formatMessage({
                  id: levelData.title,
                  defaultMessage: levelData.title,
                })
              ),
              a: translatedNodeValue(selectedNode),
            },
          ];
        } else if (levelData.mustSelectAll && selectedNode) {
          answers[key] = [];
          levelData.nodes
            .filter((n) => n.selected)
            .forEach((nn, i) => {
              answers[key].push({
                q: addCol(
                  intl.formatMessage({
                    id: nn.title,
                    defaultMessage: nn.title,
                  })
                ),
                a: translatedNodeValue(nn),
              });
            });
        }
        if (levelData.warning) {
          levelData.warning.forEach((wMsg, i) => {
            if (
              !!levelData.nodes?.find(
                (n) =>
                  (n.id === wMsg.rule || n.value === wMsg.rule) && n.selected
              )
            ) {
              answers[key][answers[key].length - 1].w = sanitizeWarningMessage(
                wMsg.message,
                lang || "en",
                intl
              );
              answers[key][answers[key].length - 1].w_show =
                wMsg.includeInLetter || false;
            }
          });
        }
      }
    );

    if (
      [
        API.PITCHED_ROOF_OUTSIDE_MAJCOAT,
        API.PITCHED_ROOF_OUTSIDE_BARRIER,
      ].includes(
        data.find((e) => !!e.api)?.api || API.PITCHED_ROOF_OUTSIDE_MAJCOAT
      )
    ) {
      headerAnswerCount = 3;
      // if (
      //   data
      //     .find(
      //       (ee) =>
      //         ee.id === SECTION_ID.PITCHED_ROOF_WOODEN_SUPPORT_PANEL_OUTSIDE
      //     )
      //     ?.nodes.find((n) => n.selected)?.value === "No"
      // ) {
      //   const _section = pitchedRoofOpenMajcoatSections.find(
      //     (e) => e.id === SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE
      //   );
      //   answers[SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE] = [
      //     {
      //       q: addCol(
      //         intl.formatMessage({
      //           id: _section?.title,
      //           defaultMessage: "Which underlay should be installed?",
      //         })
      //       ),
      //       a: _section?.nodes[0].title || "Majcoat 200 / Majcoat 250",
      //     },
      //   ];
      // }
    }
    const formDataAnswers: { [key: string]: string } = {
      date: formTimeStamp.toString(),
      formLanguage: lang || "en",
    };
    formData.fields.forEach((f) => {
      formDataAnswers[f.id] = f.value;
    });
    const pdfContent = {
      pdfText1: pdfText1
        .replaceAll("{{p}}", redText(product))
        .replaceAll("{{full_name}}", formDataAnswers.form_name),
      pdfText2: pdfText2.replaceAll("{{p}}", redText(product)),
      pdfText3: roofType === RoofType.FLAT ? pdfText3Flat : pdfText3Pitched,
      pdfText4: `<p>${textProductReplacement({
        text: changeAddress(
          intl.formatMessage({
            id: statementMsg,
            defaultMessage: statementMsg,
          }),
          formDataAnswers.form_country
        ),
        product,
        intl,
      })}`,
      pdfText5: letterSignature.replaceAll("\n", "<br>"),
    };
    const labels = {
      form_siga_contact_person: intl.formatMessage({
        id: "SIGA Contact Person's Email",
        defaultMessage: "SIGA Contact Person's Email",
      }),
    };
    const letterOrderSequence = letterOrder[
      data.find((e) => !!e.api)?.api || API.FLAT_ROOF
    ].filter((key) => Object.keys(answers).find((e) => e === key));
    const dataToSend = {
      headerAnswerCount,
      answers,
      formDataAnswers,
      pdfContent,
      letterOrder: letterOrderSequence,
      labels,
      roofType,
      product,
      dateText: {
        key: intl.formatMessage({
          id: "Date of confirmation:",
          defaultMessage: "Date of confirmation:",
        }),
      },
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || "Europe/Berlin",
      api: data.find((e) => !!e.api)?.api || API.FLAT_ROOF,
    };
    dispatch(setPdfFormDataToSend(dataToSend));
    dispatch(setFormSubmitting(true));
    try {
      const res = await submitForm(dataToSend);
      if (res.status === 200) {
        // dispatch(setformSubmitted(true));
        dispatch(setFormSubmitting(false));
        navigate(`/${lang}/success`);
        return;
      } else {
        dispatch(setFormSubmitError(res.data));
      }
    } catch (e) {
      dispatch(setFormSubmitError(e as ErrorType));
    }
    dispatch(setFormSubmitting(false));
  };

  const submitButtonDisabled = !htmlContent || !scrolledDown || formSubmitting;

  return (
    <TermsAndConditionsWrapper ref={componentRef}>
      <PrintLogoArea>
        <LogoImage src={logo} alt={"SIGA"} />
      </PrintLogoArea>
      <TitleRow
        leftSide={<></>}
        center={
          <Title
            title={
              <div>
                {termsAndConditionsTitle[isSwitzerland ? "ch" : "default"]}
              </div>
            }
            color={"#000"}
          />
        }
        rightSide={
          <HowToUseButton
            onClick={() => navigate(`/${lang}/how-to-use`)}
            color="primary"
            label={intl.formatMessage({
              id: `How to use?`,
              defaultMessage: `How to use?`,
            })}
          />
        }
      />
      <BreadcrumbsContainer
        breadcrumbs={[
          breadcrumbs[0],
          { ...breadcrumbs[1], theState: "filled" },
          { ...breadcrumbs[2], theState: "current" },
          breadcrumbs[3],
        ]}
      />
      <div>
        <TermsAndConditionsArea
          ref={termsRef}
          onScroll={handleScroll}
          dangerouslySetInnerHTML={{ __html: sanitizer(htmlContent) }}
        ></TermsAndConditionsArea>
        <FooterArea>
          <ButtonContainer>
            <AcceptButton
              variant="contained"
              disableElevation
              disabled={submitButtonDisabled}
              // onClick={showResults}
              onClick={goToNextStep}
            >
              {intl.formatMessage({
                id: "Agree and Continue",
                defaultMessage: "Agree and Continue",
              })}
              {formSubmitting && !formSubmitted ? (
                <CircularProgress
                  color="info"
                  size={16}
                  style={{ position: "relative", left: "8px" }}
                />
              ) : (
                <ForwardArrow disabled={submitButtonDisabled} />
              )}
            </AcceptButton>
            <BackButton
              variant="text"
              color="inherit"
              onClick={() => navigate(`/${lang}/step-2`)}
            >
              <KeyboardArrowLeft />
              {<FormattedMessage id={`Back`} defaultMessage={"Back"} />}
            </BackButton>
          </ButtonContainer>
          {formSubmitError && (
            <WarningContainer className={`warning-message open`}>
              {intl.formatMessage({
                id: `Failed to fetch data.`,
                defaultMessage: `Failed to fetch data.`,
              })}
              <a
                href="#"
                style={{ paddingLeft: "5px" }}
                onClick={(e) => {
                  e.preventDefault();
                  goToNextStep();
                }}
              >
                {intl.formatMessage({
                  id: `Please try again`,
                  defaultMessage: `Please try again`,
                })}
              </a>
            </WarningContainer>
          )}
        </FooterArea>
      </div>
    </TermsAndConditionsWrapper>
  );
};

export default TermsAndConditions;
