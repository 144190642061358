import { API, BUTTON_TYPE, Condition, SECTION_ID } from "../../types";
import pitchedRoof_barrier from "../../../assets/pitchedRoof_barrier.svg";
import {
  angleNodes,
  notSupportedMessage,
  pitchedRoofBarrierWarningMsg,
} from "../../constants";

export const pitchedRoofVaporBarrierSections = [
  {
    id: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
    title:
      "Type of insulation material between the rafters (full rafter insulation):",
    divider: true,
    nr: 1,
    highlightNode: {
      top: "164px",
      left: "",
      width: "100%",
      height: "98px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "1",
      top: "205px",
      wrapperLeft: "0%",
      left: "360px",
      imgTop: "8%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_b_5_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_5_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_5_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      { id: "pr_b_5_4", title: "Other", value: "Other", end: true },
      {
        id: "pr_b_5_6",
        title: "Hemp / sheep wool",
        value: "Hemp / sheep wool",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_b_5_4",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
    title:
      "What material properties does the intermediate rafter insulation have?",
    // nextSection: SECTION_ID.WOODEN_SUPPORT_PANEL,
    highlightNode: {
      top: "164px",
      left: "",
      width: "100%",
      height: "98px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "1",
      top: "205px",
      wrapperLeft: "0%",
      left: "360px",
      imgTop: "8%",
      width: "100%",
    },
    multipleChoice: true,
    mustSelectAll: true,
    nextSection: SECTION_ID.PITCHED_ROOF_WOODEN_SUPPORT_PANEL_BARRIER,
    nodes: [
      {
        id: "pr_b_2_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_b_2_1_15",
            title: "120",
            value: "120",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_1",
            title: "130",
            value: "130",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_2",
            title: "140",
            value: "140",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_3",
            title: "150",
            value: "150",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_4",
            title: "160",
            value: "160",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_5",
            title: "170",
            value: "170",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_6",
            title: "180",
            value: "180",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_7",
            title: "190",
            value: "190",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_8",
            title: "200",
            value: "200",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_9",
            title: "210",
            value: "210",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_10",
            title: "220",
            value: "220",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_11",
            title: "230",
            value: "230",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_12",
            title: "240",
            value: "240",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_13",
            title: "250",
            value: "250",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_14",
            title: "other",
            value: "other",
            parent: "pr_b_2_1",
            end: true,
          },
        ],
      },
      {
        id: "pr_b_2_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        shouldShowDisabledMessage: true,
        childNodes: [
          {
            id: "pr_b_2_2_1",
            title: "0.045",
            value: "0.045",
            parent: "pr_b_2_2",
            disableRules: [
              {
                condition: Condition.OR,
                rules: [
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_1",
                  },
                ],
              },
            ],
          },
          {
            id: "pr_b_2_2_2",
            title: "0.042",
            value: "0.042",
            parent: "pr_b_2_2",
            disableRules: [
              {
                condition: Condition.OR,
                rules: [
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_2",
                  },
                ],
              },
            ],
          },
          {
            id: "pr_b_2_2_3",
            title: "0.040",
            value: "0.040",
            parent: "pr_b_2_2",
          },
          {
            id: "pr_b_2_2_4",
            title: "0.039",
            value: "0.039",
            parent: "pr_b_2_2",
            disableRules: [
              {
                condition: Condition.OR,
                rules: [
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_2",
                  },
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_6",
                  },
                ],
              },
            ],
          },
          {
            id: "pr_b_2_2_5",
            title: "0.038",
            value: "0.038",
            parent: "pr_b_2_2",
            disableRules: [
              {
                condition: Condition.OR,
                rules: [
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_2",
                  },
                ],
              },
            ],
          },
          {
            id: "pr_b_2_2_6",
            title: "0.035",
            value: "0.035",
            parent: "pr_b_2_2",
            disableRules: [
              {
                condition: Condition.OR,
                rules: [
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_3",
                  },
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_1",
                  },
                ],
              },
            ],
          },
          {
            id: "pr_b_2_2_7",
            title: "0.032",
            value: "0.032",
            parent: "pr_b_2_2",
            disableRules: [
              {
                condition: Condition.OR,
                rules: [
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_6",
                  },
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_3",
                  },
                  {
                    sectionId:
                      SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
                    nodeId: "pr_b_5_1",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_b_2_1_14",
      },
    ],
  },
  {
    title: "Is there a wooden support panel for the over-insulation?",
    id: SECTION_ID.PITCHED_ROOF_WOODEN_SUPPORT_PANEL_BARRIER,
    nr: 2,
    divider: true,
    highlightNode: {
      top: "163px",
      left: "0px",
      width: "100%",
      height: "10px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "2",
      top: "152px",
      wrapperLeft: "0%",
      left: "140px",
      imgTop: "8%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_b_7_1",
        title: "Yes",
        value: "Yes",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_BARRIER,
      },
      {
        id: "pr_b_7_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_BARRIER,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_BARRIER,
    title: "Type of insulation over the rafters",
    divider: true,
    nr: 3,
    highlightNode: {
      top: "82px",
      left: "",
      width: "100%",
      height: "75px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "3",
      top: "104px",
      wrapperLeft: "0%",
      left: "235px",
      imgTop: "8%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_b_9_4",
        title: "PUR / PIR / PH",
        value: "PUR / PIR / PH",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_9_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_9_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_9_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
    title: "What material properties does on-roof insulation have?",
    api: API.PITCHED_ROOF_OUTSIDE_BARRIER,
    paramsFetched: false,
    highlightNode: {
      top: "82px",
      left: "",
      width: "100%",
      height: "75px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "3",
      top: "104px",
      wrapperLeft: "0%",
      left: "235px",
      imgTop: "8%",
      width: "100%",
    },
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    sequential: true,
    nextSection: SECTION_ID.PITCHED_ROOF_VENTILATED_ROOFING_MATERIAL_BARRIER,
    nodes: [
      {
        id: "pr_b_10_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        shouldShowDisabledMessage: true,
        childNodes: [
          {
            id: "pr_b_10_1_1",
            title: "52",
            value: "52",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_2",
            title: "60",
            value: "60",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_3",
            title: "80",
            value: "80",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_4",
            title: "100",
            value: "100",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_5",
            title: "120",
            value: "120",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_6",
            title: "140",
            value: "140",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_7",
            title: "160",
            value: "160",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_8",
            title: "180",
            value: "180",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_9",
            title: "other",
            value: "other",
            parent: "pr_b_10_1",
            end: true,
          },
        ],
      },
      {
        id: "pr_b_10_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        disabled: true,
        shouldShowDisabledMessage: true,
        childNodes: [
          {
            id: "pr_b_10_2_1",
            title: "0.045",
            value: "0.045",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_2",
            title: "0.042",
            value: "0.042",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_3",
            title: "0.040",
            value: "0.040",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_4",
            title: "0.039",
            value: "0.039",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_5",
            title: "0.038",
            value: "0.038",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_6",
            title: "0.035",
            value: "0.035",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_7",
            title: "0.032",
            value: "0.032",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_8",
            title: "0.029",
            value: "0.029",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_9",
            title: "0.027",
            value: "0.027",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_10",
            title: "0.025",
            value: "0.025",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_11",
            title: "0.023",
            value: "0.023",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_12",
            title: "0.022",
            value: "0.022",
            parent: "pr_b_10_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_b_10_1_9",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_VENTILATED_ROOFING_MATERIAL_BARRIER,
    title: "Which ventilated roofing material is used?",
    visualNode: {
      img: pitchedRoof_barrier,
      label: "3",
      top: "104px",
      wrapperLeft: "0%",
      left: "235px",
      imgTop: "8%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_b_13_1",
        title: "Brick/concrete roof tiles",
        value: "Brick/concrete roof tiles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_BARRIER,
      },
      {
        id: "pr_b_13_2",
        title: "Metal roofing",
        value: "Metal roofing",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_BARRIER,
      },
      {
        id: "pr_b_13_3",
        title: "Slate",
        value: "Slate",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_BARRIER,
      },
      {
        id: "pr_b_13_4",
        title: "PV - in-roof system",
        value: "PV - in-roof system",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_BARRIER,
      },
      {
        id: "pr_b_13_6",
        title: "Shingles",
        value: "Shingles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_BARRIER,
      },
      {
        id: "pr_b_13_5",
        title: "Other",
        value: "Other",
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_b_13_5",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_BARRIER,
    title: "Color of the covering?",
    visualNode: {
      img: pitchedRoof_barrier,
      label: "3",
      top: "104px",
      wrapperLeft: "0%",
      left: "235px",
      imgTop: "8%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_b_14_1",
        title: "Dark (Black, anthracite)",
        value: "Dark (Black, anthracite)",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_BARRIER,
      },
      {
        id: "pr_b_14_2",
        title: "Light (White, beige)",
        value: "Light (White, beige)",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_BARRIER,
      },
      {
        id: "pr_b_14_3",
        title: "Green, red & brown",
        value: "Green, red & brown",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_BARRIER,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_BARRIER,
    title: "Shade is caused by",
    visualNode: {
      img: pitchedRoof_barrier,
      label: "3",
      top: "104px",
      wrapperLeft: "0%",
      left: "235px",
      imgTop: "8%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_b_15_1",
        title: "Solar panel",
        value: "Solar panel",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER_BARRIER,
      },
      {
        id: "pr_b_15_2",
        title: "Buildings / Trees",
        value: "Buildings / Trees",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER_BARRIER,
      },
      {
        id: "pr_b_15_3",
        title: "No shade",
        value: "No shade",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER_BARRIER,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER_BARRIER,
    title: "Which vapour control layer (VCL) do you want to use?",
    nr: 4,
    divider: true,
    highlightNode: {
      top: "156px",
      left: "",
      width: "100%",
      height: "9px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "4",
      top: "144px",
      wrapperLeft: "0%",
      left: "330px",
      imgTop: "8%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_b_16_3",
        title: "Majpell 35 SOB",
        value: "Majpell 35 SOB",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE_BARRIER,
      },
      {
        id: "pr_b_16_1",
        title: "Majrex",
        value: "Majrex",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE_BARRIER,
      },
      {
        id: "pr_b_16_2",
        title: "Majpell 5 / Majpell 5 R",
        value: "Majpell 5 / Majpell 5 R",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE_BARRIER,
      },
    ],
    warning: [
      {
        message: pitchedRoofBarrierWarningMsg,
        rule: "pr_b_16_2",
        includeInLetter: true,
      },
      {
        message: pitchedRoofBarrierWarningMsg,
        rule: "pr_b_16_1",
        includeInLetter: true,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE_BARRIER,
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    // sequential: true,
    visualNode: {
      img: pitchedRoof_barrier,
      label: "4",
      top: "144px",
      wrapperLeft: "0%",
      left: "330px",
      imgTop: "8%",
      width: "100%",
      nodeActive: false,
    },
    nextSection:
      SECTION_ID.PITCHED_ROOF_ADDITIONAL_UNDERLAYMENT_OUTSIDE_BARRIER,
    nodes: angleNodes.map((node) => {
      if (node.id === "pr_11_2") {
        return {
          ...node,
          disabled: false,
        };
      }
      return node;
    }),
  },
  {
    id: SECTION_ID.PITCHED_ROOF_ADDITIONAL_UNDERLAYMENT_OUTSIDE_BARRIER,
    title:
      "Should an additional underlayment be applied above the roof insulation?",
    divider: false,
    visualNode: {
      img: pitchedRoof_barrier,
      label: "4",
      top: "144px",
      wrapperLeft: "0%",
      left: "330px",
      imgTop: "8%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_b_18_1",
        title: "Yes",
        value: "Yes",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE_BARRIER,
      },
      {
        id: "pr_b_18_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.FORM,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE_BARRIER,
    title: "Which underlay should be installed?",
    visualNode: {
      img: pitchedRoof_barrier,
      label: "4",
      top: "144px",
      wrapperLeft: "0%",
      left: "330px",
      imgTop: "8%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_b_19_1",
        title: "Majcoat 200 / Majcoat 250",
        value: "Majcoat 200 / Majcoat 250",
        nextSection: SECTION_ID.FORM,
      },
      {
        id: "pr_b_19_2",
        title: "Majcoat 350",
        value: "Majcoat 350",
        nextSection: SECTION_ID.FORM,
      },
      {
        id: "pr_b_19_3",
        title: "Underlay membrane sd value ≤ 0.1 m",
        value: "Underlay membrane sd value ≤ 0.1 m",
        nextSection: SECTION_ID.FORM,
      },
    ],
  },
];
