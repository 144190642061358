/* eslint-disable */

export default {
  en: {
    ["40mm"]: "40mm",
    ["60 mm"]: "60 mm",
    ["80 mm"]: "80 mm",
    ["100mm"]: "100 mm",
    ["120mm"]: "120 mm",
    [">120"]: "> 120 mm",
    ["≥ 320"]: "≥ 320 mm",
    ["120 - 139"]: "120 - 139",
    ["140 - 159"]: "140 - 159",
    ["160 - 179"]: "160 - 179",
    ["180 - 199"]: "180 - 199",
    ["200 - 219"]: "200 - 219",
    ["220 - 239"]: "220 - 239",
    ["240 - 259"]: "240 - 259",
    ["260 - 279"]: "260 - 279",
    ["280 - 299"]: "280 - 299",
    ["300 - 320"]: "300 - 320",
    ["280 - 300"]: "280 - 300",
    ["0.041 - 0.043"]: "0.041 - 0.043",
    ["0.041 - 0.045"]: "0.041 - 0.045",
    ["0.031 - 0.035"]: "0.031 - 0.035",
    ["0.025 - 0.030"]: "0.025 - 0.030",
    ["0.036 - 0.040"]: "0.036 - 0.040",
    ["0.026 - 0.030"]: "0.026 - 0.030",
    ["0.044 - 0.047"]: "0.044 - 0.047",
    ["0.035 - 0.040"]: "0.035 - 0.040",
    ["0.032 - 0.034"]: "0.032 - 0.034",
    ["5° - 9°"]: "5° - 9°",
    ["10° - 19°"]: "10° - 19°",
    ["20° - 29°"]: "20° - 29°",
    ["30° - 39°"]: "30° - 39°",
    ["40° - 49°"]: "40° - 49°",
    ["50° - 59°"]: "50° - 59°",
    ["60° - 69°"]: "60° - 69°",
    ["70° - 79°"]: "70° - 79°",
    ["80° - 89°"]: "80° - 89°",
    ["Digital checklist for Your SIGA product"]:
      "Digital checklist for Your SIGA product",
    ["Calculation Confirmation"]: "Hygrothermal Confirmation",
    ["How to use?"]: "How to use?",
    ["Type of construction:"]: "Type of construction:",
    ["New construction"]: "New construction",
    ["Renovation"]: "Renovation",
    ["Type of roof:"]: "Type of roof:",
    ["Flat roof (<5°)"]: "Flat roof (<5°)",
    ["Pitched roof (>5°)"]: "Pitched roof (>5°)",
    ["Other"]: "Other",
    ["other"]: "other",
    ["Roof build-up:"]: "Roof build-up:",
    ["Warm roof with Insulation above the deck only"]:
      "Warm roof with Insulation above the deck only",
    ["Cold roof with active ventilation"]: "Cold roof with active ventilation",
    ["Flat roof with insulation above and below the deck"]:
      "Flat roof with insulation above and below the deck",
    ["Not ventilated with on-roof insulation"]:
      "Not ventilated with on-roof insulation",
    ["Type of the insulation between the rafters"]:
      "Type of the insulation between the rafters",
    ["Cellulose"]: "Cellulose",
    ["Mineral wool"]: "Mineral wool",
    ["Wood Fibre"]: "Wood Fibre",
    ["No insulation"]: "No insulation",
    ["Hemp / sheep wool"]: "Hemp / sheep wool",
    ["Insulation layer thickness (mm):"]: "Insulation layer thickness (mm):",
    ["Insulation layer thickness (m):"]: "Insulation layer thickness (m):",
    ["Lambda value λ(w/mK):"]: "Lambda value λ(w/mK):",
    ["Is there a wooden support panel for the over-insulation?"]:
      "Is there a wooden support panel for the over-insulation?",
    ["Yes"]: "Yes",
    ["No"]: "No",
    ["Important: We recomend to install Bitum membran (SD value bigger than 100m) on the to protect wood susbrstate membrane."]:
      "Important: We recomend to install Bitum membran (SD value bigger than 100m) on the to protect wood susbrstate membrane.",
    ["What is the type of wood substrate?"]:
      "What is the type of wood substrate?",
    ["OSB"]: "OSB",
    ["Wooden formwork"]: "Wooden formwork",
    ["3 layer plate"]: "3 layer plate",
    ["What is the type of waterproofing?"]:
      "What is the type of waterproofing?",
    ["EPDM"]: "EPDM",
    ["PVC / EVA"]: "PVC / EVA",
    ["Bitumen"]: "Bitumen",
    ["Colour of the waterproofing membrane:"]:
      "Colour of the waterproofing membrane:",
    ["Dark (Black, anthracite)"]: "Dark (Black, anthracite)",
    ["Middle (Grey)"]: "Middle (Grey)",
    ["Green, red & brown"]: "Green, red and brown",
    ["Light (White, beige)"]: "Light (White, beige)",
    ["Shade"]: "Shade",
    ["Shade is caused by"]: "Shade is caused by",
    ["Solar panel"]: "Solar panel",
    ["Terrace (wood or stone)"]: "Terrace (wood or stone)",
    ["Vegetation (0 - 100 mm)"]: "Vegetation (0 - 100 mm)",
    ["Pebbles (max. 120 mm)"]: "Pebbles (max. 120 mm)",
    ["Building"]: "Building / Parapet > 800 mm",
    ["Which vapour control layer (VCL) do you want to use?"]:
      "Which vapour control layer (VCL) do you want to use?",
    ["External over-insulation (bio-based insulation is excluded):"]:
      "External over-insulation (bio-based insulation is excluded):",
    ["Minimum insulation thickness in the layer (mm):"]:
      "<b>Minimum insulation</b> thickness in the layer (mm):",
    ["0.025 or less"]: "≤ 0.025",
    ["Successful Parameters"]: "Successful Parameters",
    ["Company"]: "Company",
    ["Name / First name"]: "Name / First name",
    ["Branch"]: "Branch",
    ["Street and house number"]: "Street and house number",
    ["Postal code"]: "Postal code",
    ["City"]: "City",
    ["Country"]: "Country",
    ["Construction site address"]: "Construction site address",
    ["Construction site name"]: "Construction site name",
    ["Builder"]: "Builder",
    ["Submit"]: "Submit",
    ["Majpell 5"]: "Majpell 5",
    ["Majrex"]: "Majrex 200",
    ["Majrex 200"]: "Majrex 200",
    ["Majpell 5 / Majpell 5 R"]: "Majpell 5 / Majpell 5 R",
    ["Currently we do not support this configuration. For more information please consult the PDF form."]:
      "Currently we do not support this configuration.&nbsp;{link}Please complete our Checklist in .pdf format{linkEnd}",
    ["Nachweisfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}"]:
      "Nachweisfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}",
    ["Date of confirmation:"]: "Date of confirmation:",
    ["Back"]: "Back",
    ["Contact information"]: "Contact information",
    ["Failed to fetch data."]: "Failed to fetch data.",
    ["Please try again"]: "Please try again",
    ["Email"]: "Email",
    ["Phone number"]: "Phone number",
    ["Print document"]: "Print document",
    ['Based on our calculation with the hygrothermal simulation program (EN 15026) "Delphin - The IBK institute in Dresden / Germany" we can adopt the following statement:\nIn any case, it must be ensured that the minimum thermal insulation requirements are met. This letter refers exclusively to the moisture-related functionality of the described construction. If the general terms and conditions are met,\n for this construction, the use of the vapor barrier is appropriate Majrex SIGA SIGA Cover AG gives you full warranty on all properties guaranteed in the instruction manual for SIGA Majrex.\nApply the SIGA products always according to the SIGA instruction manual. Professional planning, execution and control on-site is required. To ensure a proper hygrothermic function, an accurately assembled airtight-layer is essential.']:
      'Based on our calculation with the hygrothermal simulation program (EN 15026) "Delphin - The IBK institute in Dresden / Germany" we can adopt the following statement:\nIn any case, it must be ensured that the minimum thermal insulation requirements are met. This letter refers exclusively to the moisture-related functionality of the described construction. If the general terms and conditions are met,\n for this construction, the use of the vapor barrier is appropriate Majrex SIGA SIGA Cover AG gives you full warranty on all properties guaranteed in the instruction manual for SIGA Majrex.\nApply the SIGA products always according to the SIGA instruction manual. Professional planning, execution and control on-site is required. To ensure a proper hygrothermic function, an accurately assembled airtight-layer is essential.',
    ["Customer support"]: "Customer support",
    ["Continue"]: "Continue",
    ["In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer."]:
      "Eine noch höhere Sicherheit bietet Ihnen neben den bewährten SIGA-Klebeprodukten der Einsatz der Dampfbremsbahn Majrex 200.<br></br> Mit Ihrer Hygrobrid-Technologie und noch mehr Sicherheit, erfüllt sie den Wunsch vieler Handwerker, alles aus einer Hand zu erhalten. Eine Vielzahl unterschiedlicher Anwendungen lassen sich mit der universell einsetzbaren und leicht zu verarbeitenden Dampfbremse abdecken.",
    ["The beta version of this application is currently only supported on desktop devices"]:
      "The beta version of this application is currently only supported on desktop devices",
    ["No shade"]: "No shade",
    ["Selection successful"]: "Selection successful",
    ["Agree and Continue"]: "Agree and Continue",
    ["This field is required"]: "This field is required",
    ["Please provide a valid email address"]:
      "Please provide a valid email address",
    ["Please provide a valid telephone number"]:
      "Please provide a valid telephone number",
    ["Validation error"]: "Validation error",
    ["Checklist"]: "Checklist",
    ["Review"]: "Review",
    ["AGB"]: "AGB",
    ["Done"]: "Done",
    ["You will find your SIGA approval letter below"]:
      "You will find your SIGA approval letter below",
    ["You will receive this in just a few seconds"]:
      "You will receive this in just a few seconds",
    ["Warranty letter"]: "Warranty letter",
    ["To checklist"]: "To checklist",
    ["Frequently asked questions (FAQ)"]: "Frequently asked questions (FAQ)",
    ["Nachweisfrei unter Einhaltung der a.a.R.d.T.."]:
      "Nachweisfrei unter Einhaltung der a.a.R.d.T..",
    ["SIGA Contact Person's Email"]: "SIGA Contact Person's Email",
    ["This configuration is not available"]:
      "This configuration is not available",
    ["This construction is not physically possible"]:
      "This construction is not physically possible",
    ["From which side is the vapor barrier / airtightness membrane installed?"]:
      "From which side is the vapor barrier / airtightness membrane installed?",

    ["From the inside"]: "From the inside",
    ["From the outside"]: "From the outside",
    ["Open to diffusion with/without installation underlay"]:
      "Open to diffusion with/without installation underlay",
    ["Diffusion-tight with wood layer (Sd >20 m)"]:
      "Diffusion-tight with wood layer (Sd >20 m)",
    ["Diffusion-open with wood layer"]: "Diffusion-open with wood layer",
    ["Type of insulation material between the rafters (full rafter insulation):"]:
      "Type of insulation material between the rafters (full rafter insulation):",
    ["What material properties does the intermediate rafter insulation have?"]:
      "What material properties does the intermediate rafter insulation have?",
    ["What type of sealing is above the wooden formwork?"]:
      "What type of sealing is above the wooden formwork?",
    ["Diffusible undercover"]: "Diffusible undercover",
    ["Which covering material is used?"]: "Which covering material is used?",
    ["Brick/concrete roof tiles"]: "Brick/concrete roof tiles",
    ["Metal roofing"]: "Metal roofing",
    ["Slate"]: "Slate",
    ["No additional covering (bare roof)"]:
      "No additional covering (bare roof)",
    ["Bitumen membrane / shingles"]: "Bitumen membrane / shingles",
    ["Color of the covering?"]: "Color of the covering?",
    ["Buildings / Trees"]: "Buildings / Trees",
    ["Alignment (worst situation)"]: "Alignment (worst situation)",
    ["Roof pitch"]: "Roof pitch",
    ["North"]: "North",
    ["East"]: "East",
    ["South"]: "South",
    ["West"]: "West",
    ["Roof insulation with vapor barrier"]:
      "Roof insulation with vapor barrier",
    ["Roof insulation with Majcoat 200 (SOB)"]:
      "Roof insulation with Majcoat 200 (SOB)",
    ["Roof insulation with sub-top variant"]:
      "Roof insulation with sub-top variant",
    ["Type of insulation over the rafters"]:
      "Type of insulation over the rafters",
    ["What material properties does on-roof insulation have?"]:
      "What material properties does on-roof insulation have?",
    ["Should an additional underlayment be applied above the roof insulation?"]:
      "Should an additional underlayment be applied above the roof insulation?",
    ["Which underlay should be installed?"]:
      "Which underlay should be installed?",
    ["Majcoat 200 / Majcoat 250"]: "Majcoat 200 (SOB) / Majcoat 250 (SOB)",
    ["Majcoat 350"]: "Majcoat 350",
    ["Which ventilated roofing material is used?"]:
      "Which ventilated roofing material is used?",
    ["PV - in-roof system"]: "PV - in-roof system",
    ["Underlay membrane sd value ≤ 0.1 m"]:
      "Underlay membrane sd value ≤ 0.1 m",
    ["There must be no additional airtight layer between the vapor barrier and the insulation between the rafters on the construction side."]:
      "There must be no additional airtight layer between the vapor barrier and the insulation between the rafters on the construction side.\n",
    ["Majcoat 200 (SOB)"]: "Majcoat 200 (SOB)",
    ["Detect-free under the following conditions.\n{link}SIGA tracks in various constructions{linkEnd}"]:
      "Detect-free under the following conditions.\n{link}SIGA tracks in various constructions{linkEnd}",
    ["PUR / PIR / PH"]: "PUR / PIR / PH",
    ["Shingles"]: "Shingles",
    ["Majpell 35 SOB"]: "Majpell 35 SOB",
    ["The vapor barrier membranes Majrex 200 and Majpell 5 / Majpell 5 R are not suitable for use as temporary covering and must be built over as soon as possible. Majpell 35 SOB can be exposed to the elements for a period of up to 4 weeks."]:
      "The vapor barrier membranes Majrex 200 and Majpell 5 / Majpell 5 R are not suitable for use as temporary covering and must be built over as soon as possible. Majpell 35 SOB can be exposed to the elements for a period of up to 4 weeks.",
    ["Should Wetguard 200 SA be installed as construction protection?"]:
      "Should Wetguard 200 SA be installed as construction protection?",
    ["Attention: Does not replace the first waterproofing layer / vapor barrier in the construction!"]:
      "Attention: Does not replace the first waterproofing layer / vapor barrier in the construction!",
    ["Only letters, spaces, and hyphens are allowed"]:
      "Only letters, spaces, and hyphens are allowed",
  },
  fr: {
    ["40mm"]: "40 mm",
    ["60 mm"]: "60 mm",
    ["80 mm"]: "80 mm",
    ["100mm"]: "100 mm",
    ["120mm"]: "120 mm",
    [">120"]: "> 120 mm",
    ["≥ 320"]: "≥ 320 mm",
    ["120 - 139"]: "120 - 139",
    ["140 - 159"]: "140 - 159",
    ["160 - 179"]: "160 - 179",
    ["180 - 199"]: "180 - 199",
    ["200 - 219"]: "200 - 219",
    ["220 - 239"]: "220 - 239",
    ["240 - 259"]: "240 - 259",
    ["260 - 279"]: "260 - 279",
    ["280 - 299"]: "280 - 299",
    ["300 - 320"]: "300 - 320",
    ["280 - 300"]: "280 - 300",
    ["0.041 - 0.043"]: "0.041 - 0.043",
    ["0.041 - 0.045"]: "0.041 - 0.045",
    ["0.031 - 0.035"]: "0.031 - 0.035",
    ["0.025 - 0.030"]: "0.025 - 0.030",
    ["0.036 - 0.040"]: "0.036 - 0.040",
    ["0.026 - 0.030"]: "0.026 - 0.030",
    ["0.044 - 0.047"]: "0.044 - 0.047",
    ["0.035 - 0.040"]: "0.035 - 0.040",
    ["0.032 - 0.034"]: "0.032 - 0.034",
    ["5° - 9°"]: "5° - 9°",
    ["10° - 19°"]: "10° - 19°",
    ["20° - 29°"]: "20° - 29°",
    ["30° - 39°"]: "30° - 39°",
    ["40° - 49°"]: "40° - 49°",
    ["50° - 59°"]: "50° - 59°",
    ["60° - 69°"]: "60° - 69°",
    ["70° - 79°"]: "70° - 79°",
    ["80° - 89°"]: "80° - 89°",
    ["Digital checklist for Your SIGA product"]:
      "Checkliste digitale pour vos produits SIGA",
    ["Calculation Confirmation"]: "Confirmation Hygrothermique",
    ["How to use?"]: "Aide pour l'utilisation",
    ["Type of construction:"]: "Type de construction:",
    ["New construction"]: "Nouvelle construction",
    ["Renovation"]: "Rénovation",
    ["Type of roof:"]: "Type de toiture",
    ["Flat roof (<5°)"]: "Toiture plate (<5°)",
    ["Pitched roof (>5°)"]: "Toiture pente (>5°)",
    ["Other"]: "Autre",
    ["other"]: "autre",
    ["Roof build-up:"]: "Système de toiture:",
    ["Warm roof with Insulation above the deck only"]:
      "Warm roof with Insulation above the deck only",
    ["Cold roof with active ventilation"]: "Toiture plate ventilée",
    ["Flat roof with insulation above and below the deck"]:
      "Toiture plate avec surisolation",
    ["Not ventilated with on-roof insulation"]:
      "Not ventilated with on-roof insulation",
    ["Type of the insulation between the rafters"]:
      "Type d'isolant entre les chevrons",
    ["Cellulose"]: "Cellulose",
    ["Mineral wool"]: "Laine minérale",
    ["Wood Fibre"]: "Laine de bois",
    ["No insulation"]: "Pas d'isolant",
    ["Hemp / sheep wool"]: "Hanf / Schafwolle",
    ["Insulation layer thickness (mm):"]: "Épaisseur de l'isolant (mm):",
    ["Insulation layer thickness (m):"]: "Épaisseur de l'isolant (m):",
    ["Lambda value λ(w/mK):"]: "Valeur lambda λ(W/mK):",
    ["Is there a wooden support panel for the over-insulation?"]:
      "Y a-t-il un panneau bois support pour la surisolation?",
    ["Yes"]: "Oui",
    ["No"]: "Non",
    ["Important: We recomend to install Bitum membran (SD value bigger than 100m) on the to protect wood susbrstate membrane."]:
      "Important: nous recommandons d'installer le pare-vapeur du système d'étanchéité sur ce support bois.",
    ["What is the type of wood substrate?"]: "Quel est le matériau utilisé?",
    ["OSB"]: "OSB / Panneau 3 plis",
    ["Wooden formwork"]: "Panneau particules",
    ["3 layer plate"]: "Volige",
    ["What is the type of waterproofing?"]:
      "Quel type de membrane est utilisée pour l'étanchéité du toit plat?",
    ["EPDM"]: "EPDM",
    ["PVC / EVA"]: "PVC / EVA",
    ["Bitumen"]: "Bitumen",
    ["Colour of the waterproofing membrane:"]:
      "Couleur de la membrane d'étanchéité:",
    ["Dark (Black, anthracite)"]: "Foncée (noir, anthracite)",
    ["Middle (Grey)"]: "Entre deux (gris)",
    ["Green, red & brown"]: "Grün, rot, braun",
    ["Light (White, beige)"]: "Clair (blanc, beige)",
    ["Shade"]: "Ombrage",
    ["Shade is caused by"]: "L'ombrage est causée par",
    ["Solar panel"]: "Panneau solaire",
    ["Terrace (wood or stone)"]: "Terrasse (bois ou pierre)",
    ["Vegetation (0 - 100 mm)"]: "Toiture végétalisée (0 - 100 mm)",
    ["Pebbles (max. 120 mm)"]: "Cailloux (max 120 mm)",
    ["Building"]: "Bâtiment / Accrotère > 800 mm",
    ["Which vapour control layer (VCL) do you want to use?"]:
      "Quel frein vapeur voulez-vous utiliser?",
    ["External over-insulation (bio-based insulation is excluded):"]:
      "Surisolation extérieure (les isolants biosourcés sont exclus):",
    ["Minimum insulation thickness in the layer (mm):"]:
      "<b>Epaisseur minimale</b> de la surisolation en toiture (mm):",
    ["0.025 or less"]: "≤ 0.025",
    ["Successful Parameters"]: "Paramètres validés",
    ["Company"]: "Société",
    ["Name / First name"]: "Nom / Prénom",
    ["Branch"]: "Branche",
    ["Street and house number"]: "Street and house number",
    ["Postal code"]: "Postal code",
    ["City"]: "City",
    ["Country"]: "Country",
    ["Construction site address"]: "Adresse du chantier",
    ["Construction site name"]: "Name Bauvorhaben",
    ["Builder"]: "Bauherr",
    ["Submit"]: "Soumettre",
    ["Majpell 5"]: "Majpell 5",
    ["Majrex"]: "Majrex 200",
    ["Majrex 200"]: "Majrex 200",
    ["Majpell 5 / Majpell 5 R"]: "Majpell 5 / Majpell 5 R",
    ["Currently we do not support this configuration. For more information please consult the PDF form."]:
      "Actuellement nous ne prenons pas en charge cette configuration.&nbsp;{link}Merci de bien vouloir remplir notre Checkliste au format .pdf{linkEnd}",
    ["Nachweisfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}"]:
      "Nachweisfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}",
    ["Date of confirmation:"]: "Date de confirmation:",
    ["Back"]: "Retour",
    ["Contact information"]: "Information de contact",
    ["Failed to fetch data."]: "Echec de la récupération des données.",
    ["Please try again"]: "Merci de bien vouloir réessayer",
    ["Email"]: "E-mail",
    ["Phone number"]: "Téléphone",
    ["Print document"]: "Imprimer le document",
    ['Based on our calculation with the hygrothermal simulation program (EN 15026) "Delphin - The IBK institute in Dresden / Germany" we can adopt the following statement:\nIn any case, it must be ensured that the minimum thermal insulation requirements are met. This letter refers exclusively to the moisture-related functionality of the described construction. If the general terms and conditions are met,\n for this construction, the use of the vapor barrier is appropriate Majrex SIGA SIGA Cover AG gives you full warranty on all properties guaranteed in the instruction manual for SIGA Majrex.\nApply the SIGA products always according to the SIGA instruction manual. Professional planning, execution and control on-site is required. To ensure a proper hygrothermic function, an accurately assembled airtight-layer is essential.']:
      "Dans tous les cas, il faut s'assurer que les exigences minimales en matière d'isolation thermique sont respectées. Cette lettre se réfère exclusivement à la capacité de fonctionnement en matière d'humidité de la structure décrite.\nSi les conditions préalables et les conditions marginales des CGV sont remplies, la membrane pare-vapeur Majrex 200 est appropriée pour cette construction, SIGA Cover AGassume la pleine garantie sur toutes les propriétés de Majrex 200 assurées dans le mode d'emploi. Nous supposons une planification, une exécution et un contrôle sur place dans les règles de l'art.\nVeuillez toujours tenir compte de notre mode d'emploi actuel lors de la mise en œuvre des produits SIGA.",
    ["Customer support"]: "Service client",
    ["Continue"]: "Continuer",
    ["In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer."]:
      "Eine noch höhere Sicherheit bietet Ihnen neben den bewährten SIGA-Klebeprodukten der Einsatz der Dampfbremsbahn Majrex 200.<br></br> Mit Ihrer Hygrobrid-Technologie und noch mehr Sicherheit, erfüllt sie den Wunsch vieler Handwerker, alles aus einer Hand zu erhalten. Eine Vielzahl unterschiedlicher Anwendungen lassen sich mit der universell einsetzbaren und leicht zu verarbeitenden Dampfbremse abdecken.",
    ["The beta version of this application is currently only supported on desktop devices"]:
      "La version bêta de cette application n'est actuellement prise en charge que sur les appareils de bureau",
    ["No shade"]: "Pas d’ombrage",
    ["Selection successful"]: "Sélection réussie",
    ["Agree and Continue"]: "Accepter et continuer",
    ["This field is required"]: "Ce champ est obligatoire",
    ["Please provide a valid email address"]:
      "Merci de bien vouloir nous communiquer une adresse e-mail valide",
    ["Please provide a valid telephone number"]:
      "Merci de bien vouloir nous communiquer un numéro de téléphone valide",
    ["Validation error"]: "Erreur de validation",
    ["Checklist"]: "Liste de contrôle",
    ["Review"]: "Vérification",
    ["AGB"]: "AGB",
    ["Done"]: "Done",
    ["You will find your SIGA approval letter below"]:
      "You will find your SIGA approval letter below",
    ["You will receive this in just a few seconds"]:
      "You will receive this in just a few seconds",
    ["Warranty letter"]: "Lettre de garantie",
    ["To checklist"]: "À la liste de contrôle",
    ["Frequently asked questions (FAQ)"]: "Frequently asked questions (FAQ)",
    ["Nachweisfrei unter Einhaltung der a.a.R.d.T.."]:
      "Nachweisfrei unter Einhaltung der a.a.R.d.T..",
    ["SIGA Contact Person's Email"]: "SIGA Contact Person's Email",
    ["This configuration is not available"]:
      "This configuration is not available",
    ["This construction is not physically possible"]:
      "This construction is not physically possible",
    ["From which side is the vapor barrier / airtightness membrane installed?"]:
      "From which side is the vapor barrier / airtightness membrane installed?",
    ["From the inside"]: "From the inside",
    ["From the outside"]: "From the outside",
    ["Open to diffusion with/without installation underlay"]:
      "Open to diffusion with/without installation underlay",
    ["Diffusion-tight with wood layer (Sd >20 m)"]:
      "Diffusion-tight with wood layer (Sd >20 m)",
    ["Diffusion-open with wood layer"]: "Diffusion-open with wood layer",
    ["Type of insulation material between the rafters (full rafter insulation):"]:
      "Type of insulation material between the rafters (full rafter insulation):",
    ["What material properties does the intermediate rafter insulation have?"]:
      "What material properties does the intermediate rafter insulation have?",
    ["What type of sealing is above the wooden formwork?"]:
      "What type of sealing is above the wooden formwork?",
    ["Diffusible undercover"]: "Diffusible undercover",
    ["Which covering material is used?"]: "Which covering material is used?",
    ["Brick/concrete roof tiles"]: "Brick/concrete roof tiles",
    ["Metal roofing"]: "Metal roofing",
    ["Slate"]: "Slate",
    ["No additional covering (bare roof)"]:
      "No additional covering (bare roof)",
    ["Bitumen membrane / shingles"]: "Bitumen membrane / shingles",
    ["Color of the covering?"]: "Color of the covering?",
    ["Buildings / Trees"]: "Buildings / Trees",
    ["Alignment (worst situation)"]: "Alignment (worst situation)",
    ["Roof pitch"]: "Roof pitch",
    ["North"]: "North",
    ["East"]: "East",
    ["South"]: "South",
    ["West"]: "West",
    ["Roof insulation with vapor barrier"]:
      "Roof insulation with vapor barrier",
    ["Roof insulation with Majcoat 200 (SOB)"]:
      "Roof insulation with Majcoat 200 (SOB)",
    ["Roof insulation with sub-top variant"]:
      "Roof insulation with sub-top variant",
    ["Type of insulation over the rafters"]:
      "Type of insulation over the rafters",
    ["What material properties does on-roof insulation have?"]:
      "What material properties does on-roof insulation have?",
    ["Should an additional underlayment be applied above the roof insulation?"]:
      "Should an additional underlayment be applied above the roof insulation?",
    ["Which underlay should be installed?"]:
      "Which underlay should be installed?",
    ["Majcoat 200 / Majcoat 250"]: "Majcoat 200 (SOB) / Majcoat 250 (SOB)",
    ["Majcoat 350"]: "Majcoat 350",
    ["Which ventilated roofing material is used?"]:
      "Which ventilated roofing material is used?",
    ["PV - in-roof system"]: "PV - in-roof system",
    ["Underlay membrane sd value ≤ 0.1 m"]:
      "Underlay membrane sd value ≤ 0.1 m",
    ["There must be no additional airtight layer between the vapor barrier and the insulation between the rafters on the construction side."]:
      "There must be no additional airtight layer between the vapor barrier and the insulation between the rafters on the construction side.\n",
    ["Majcoat 200 (SOB)"]: "Majcoat 200 (SOB)",
    ["Detect-free under the following conditions.\n{link}SIGA tracks in various constructions{linkEnd}"]:
      "Detect-free under the following conditions.\n{link}SIGA tracks in various constructions{linkEnd}",
    ["PUR / PIR / PH"]: "PUR / PIR / PH",
    ["Shingles"]: "Shingles",
    ["Majpell 35 SOB"]: "Majpell 35 SOB",
    ["The vapor barrier membranes Majrex 200 and Majpell 5 / Majpell 5 R are not suitable for use as temporary covering and must be built over as soon as possible. Majpell 35 SOB can be exposed to the elements for a period of up to 4 weeks."]:
      "The vapor barrier membranes Majrex 200 and Majpell 5 / Majpell 5 R are not suitable for use as temporary covering and must be built over as soon as possible. Majpell 35 SOB can be exposed to the elements for a period of up to 4 weeks.",
    ["Should Wetguard 200 SA be installed as construction protection?"]:
      "Should Wetguard 200 SA be installed as construction protection?",
    ["Attention: Does not replace the first waterproofing layer / vapor barrier in the construction!"]:
      "Attention: Does not replace the first waterproofing layer / vapor barrier in the construction!",
    ["Only letters, spaces, and hyphens are allowed"]:
      "Only letters, spaces, and hyphens are allowed",
  },
  de: {
    ["40mm"]: "40 mm",
    ["60 mm"]: "60 mm",
    ["80 mm"]: "80 mm",
    ["100mm"]: "100 mm",
    ["120mm"]: "120 mm",
    [">120"]: "> 120 mm",
    ["≥ 320"]: "≥ 320 mm",
    ["120 - 139"]: "120 - 139",
    ["140 - 159"]: "140 - 159",
    ["160 - 179"]: "160 - 179",
    ["180 - 199"]: "180 - 199",
    ["200 - 219"]: "200 - 219",
    ["220 - 239"]: "220 - 239",
    ["240 - 259"]: "240 - 259",
    ["260 - 279"]: "260 - 279",
    ["280 - 299"]: "280 - 299",
    ["300 - 320"]: "300 - 320",
    ["280 - 300"]: "280 - 300",
    ["0.041 - 0.043"]: "0.041 - 0.043",
    ["0.041 - 0.045"]: "0.041 - 0.045",
    ["0.031 - 0.035"]: "0.031 - 0.035",
    ["0.025 - 0.030"]: "0.025 - 0.030",
    ["0.036 - 0.040"]: "0.036 - 0.040",
    ["0.026 - 0.030"]: "0.026 - 0.030",
    ["0.044 - 0.047"]: "0.044 - 0.047",
    ["0.035 - 0.040"]: "0.035 - 0.040",
    ["0.032 - 0.034"]: "0.032 - 0.034",
    ["5° - 9°"]: "5° - 9°",
    ["10° - 19°"]: "10° - 19°",
    ["20° - 29°"]: "20° - 29°",
    ["30° - 39°"]: "30° - 39°",
    ["40° - 49°"]: "40° - 49°",
    ["50° - 59°"]: "50° - 59°",
    ["60° - 69°"]: "60° - 69°",
    ["70° - 79°"]: "70° - 79°",
    ["80° - 89°"]: "80° - 89°",
    ["Digital checklist for Your SIGA product"]: "Checkliste für deinen Aufbau",
    ["Calculation Confirmation"]: "Feuchtenachweis",
    ["Type of construction:"]: "Art der Konstruktion:",
    ["How to use?"]: "Hilfe zur Nutzung",
    ["New construction"]: "Neubau",
    ["Renovation"]: "Sanierung",
    ["Type of roof:"]: "Art der Dachkonstruktion",
    ["Flat roof (<5°)"]: "Flachdach (<5°)",
    ["Pitched roof (>5°)"]: "Steildach (>5°)",
    ["Other"]: "Sonstige",
    ["other"]: "sonstige",
    ["Roof build-up:"]: "Konstruktionstyp:",
    ["Warm roof with Insulation above the deck only"]:
      "Nicht belüftet mit Aufdachdämmung",
    ["Cold roof with active ventilation"]: "Belüftetes Dach",
    ["Flat roof with insulation above and below the deck"]:
      "Nicht belüftet mit Zwischensparren- und Aufdachdämmung",
    ["Not ventilated with on-roof insulation"]:
      "Nicht belüftet mit Aufdachdämmung",
    ["Type of the insulation between the rafters"]:
      "Dämmstofftyp zwischen den Sparren (Vollsparrendämmung)",
    ["Cellulose"]: "Zellulose",
    ["Mineral wool"]: "Mineralwolle",
    ["Wood Fibre"]: "Holzfaser",
    ["No insulation"]: "Kein Dämmstoff",
    ["Hemp / sheep wool"]: "Hanf / Schafwolle",
    ["Insulation layer thickness (mm):"]: "Dämmstoffdicke (mm):",
    ["Insulation layer thickness (m):"]: "Dämmstoffdicke (m):",
    ["Lambda value λ(w/mK):"]: "Wärmeleitfähigkeit λ(W/m*K):",
    ["Is there a wooden support panel for the over-insulation?"]:
      "Ist eine Holzschalung oder Holzwerkstoffplatte oberhalb der Sparren angeordnet?",
    ["Yes"]: "Ja",
    ["No"]: "Nein",
    ["Important: We recomend to install Bitum membran (SD value bigger than 100m) on the to protect wood susbrstate membrane."]:
      "Wichtig: Abdichtungsbahn / Dampfsperre mit sd-Wert ≥ 100 m, Gemäss a.a.R.d.T. wird der Einsatz einer Behelfsabdichtung / Dampfsperre mit sd-Wert ≥ 100m gefordert.",
    ["What is the type of wood substrate?"]: "Welches Material wird verwendet?",
    ["OSB"]: "OSB",
    ["Wooden formwork"]: "Holzschalung",
    ["3 layer plate"]: "3-Schichtplatte",
    ["What is the type of waterproofing?"]:
      "Welche Art von Abdichtung wird für die Flachdachabdichtung verwendet?",
    ["EPDM"]: "EPDM",
    ["PVC / EVA"]: "PVC / EVA",
    ["Bitumen"]: "Bitumen",
    ["Colour of the waterproofing membrane:"]: "Farbe der Dachabdichtungsbahn:",
    ["Dark (Black, anthracite)"]: "Dunkel (Schwarz, Anthrazit)",
    ["Middle (Grey)"]: "Mittel (Grau)",
    ["Green, red & brown"]: "Grün, rot, braun",
    ["Light (White, beige)"]: "Hell (Weiß, Beige)",
    ["Shade"]: "Schatten",
    ["Shade is caused by"]: "Schatten wird verursacht durch",
    ["Solar panel"]: "Solar / PV-Anlage",
    ["Terrace (wood or stone)"]: "Terrasse (Holz oder Stein)",
    ["Vegetation (0 - 100 mm)"]: "Begrünung (0 - 100 mm)",
    ["Pebbles (max. 120 mm)"]: "Bekiesung (max. 120 mm)",
    ["Building"]: "Gebäude / Bäume / Attika > 800 mm",
    ["Which vapour control layer (VCL) do you want to use?"]:
      "Welche Dampfbremse wird verbaut?",
    ["External over-insulation (bio-based insulation is excluded):"]:
      "Überdämmung der Konstruktion (sorptionsfähige Dämmstoffe sind ausgeschlossen):",
    ["Minimum insulation thickness in the layer (mm):"]:
      "<b>Mindestdicke</b> der Überdämmung (mm):",
    ["0.025 or less"]: "≤ 0.025",
    ["Successful Parameters"]: "Auswahl erfolgreich",
    ["Company"]: "Firma",
    ["Name / First name"]: "Vorname / Name",
    ["Branch"]: "Branche",
    ["Street and house number"]: "Straße und Hausnummer",
    ["Postal code"]: "Postleitzahl",
    ["City"]: "Stadt",
    ["Country"]: "Land",
    ["Construction site address"]: "Adresse des Bauvorhaben",
    ["Construction site name"]: "Name Bauvorhaben",
    ["Builder"]: "Bauherr",
    ["Submit"]: "Kostenlos anfragen",
    ["Majpell 5"]: "Majpell 5",
    ["Majrex"]: "Majrex 200",
    ["Majrex 200"]: "Majrex 200",
    ["Majpell 5 / Majpell 5 R"]: "Majpell 5 / Majpell 5 R",
    ["Currently we do not support this configuration. For more information please consult the PDF form."]:
      "Derzeit unterstützen wir diese Konstruktion nicht.&nbsp;{link}Bitte füllen Sie unsere Checkliste im .pdf-Format aus{linkEnd}",
    ["Nachweisfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}"]:
      "Nachweisfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}",
    ["Date of confirmation:"]: "Datum der Bestätigung:",
    ["Back"]: "Zurück",
    ["Contact information"]: "Kontakt Information",
    ["Failed to fetch data."]: "Datenverarbeitung fehlgeschlagen.",
    ["Please try again"]: "Bitte versuchen Sie es noch einmal",
    ["Email"]: "E-mail",
    ["Phone number"]: "Telefon",
    ["Print document"]: "Dokument drucken",
    ['Based on our calculation with the hygrothermal simulation program (EN 15026) "Delphin - The IBK institute in Dresden / Germany" we can adopt the following statement:\nIn any case, it must be ensured that the minimum thermal insulation requirements are met. This letter refers exclusively to the moisture-related functionality of the described construction. If the general terms and conditions are met,\n for this construction, the use of the vapor barrier is appropriate Majrex SIGA SIGA Cover AG gives you full warranty on all properties guaranteed in the instruction manual for SIGA Majrex.\nApply the SIGA products always according to the SIGA instruction manual. Professional planning, execution and control on-site is required. To ensure a proper hygrothermic function, an accurately assembled airtight-layer is essential.']:
      "In jedem Fall ist sicherzustellen, dass die Mindestanforderungen an den Wärmeschutz eingehalten werden. Dieses Schreiben bezieht sich ausschliesslich auf die feuchtetechnische Funktionsfähigkeit des beschriebenen Aufbaus.\n{{flatroof_msg}}Werden die AGB eingehalten und die Randbedingungen erfüllt, ist für diese Konstruktion die Dampfbremsbahn / Luftdichtheitsbahn {{p}} geeignet, die SIGA Cover GmbH Deutschland übernimmt die volle Gewährleistung auf alle in der Gebrauchsanleitung zugesicherten Eigenschaften von {{p}}.\nEine fachgerechte Planung, Ausführung und Kontrolle vor Ort setzen wir voraus. Falls Sie eine Verschattung angegeben haben, ist diese in der Beurteilung berücksichtigt worden. Es erfolgt keine weitere dauerhafte oder zusätzliche Verschattung durch nachträgliche Aufbauten. Bitte beachten Sie stets unsere aktuelle Gebrauchsanleitung bei der Verarbeitung der SIGA-Produkte.",
    ["Customer support"]: "Kundendienst",
    ["Continue"]: "Weiter",
    ["In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer."]:
      "Eine noch höhere Sicherheit bietet Ihnen neben den bewährten SIGA-Klebeprodukten der Einsatz der Dampfbremsbahn Majrex 200.<br></br> Mit Ihrer Hygrobrid-Technologie und noch mehr Sicherheit, erfüllt sie den Wunsch vieler Handwerker, alles aus einer Hand zu erhalten. Eine Vielzahl unterschiedlicher Anwendungen lassen sich mit der universell einsetzbaren und leicht zu verarbeitenden Dampfbremse abdecken.",
    ["The beta version of this application is currently only supported on desktop devices"]:
      "Die Beta-Version dieser Anwendung wird derzeit nur auf Desktop-Geräten unterstützt",
    ["No shade"]: "Keine Beschattung",
    ["Selection successful"]: "Auswahl erfolgreich",
    ["Agree and Continue"]: "Zustimmen und weiter",
    ["This field is required"]: "Dieses Feld ist erforderlich",
    ["Please provide a valid email address"]:
      "Bitte geben Sie eine gültige E-Mail-Adresse an",
    ["Please provide a valid telephone number"]:
      "Bitte geben Sie eine gültige Telefonnummer an",
    ["Validation error"]: "Validierungsfehler",
    ["Checklist"]: "Checkliste",
    ["Review"]: "Überprüfen",
    ["AGB"]: "AGB",
    ["Done"]: "Fertig",
    ["You will find your SIGA approval letter below"]:
      "Untenstehend finden Sie Ihr SIGA-Freigabeschreiben",
    ["You will receive this in just a few seconds"]:
      "Sie bekommen dies in wenigen Sekunden zugesendet",
    ["Warranty letter"]: "Freigabeschreiben",
    ["To checklist"]: "Zur Checkliste",
    ["Frequently asked questions (FAQ)"]: "Häufig gestellte Fragen (FAQ)",
    ["Nachweisfrei unter Einhaltung der a.a.R.d.T.."]:
      "Nachweisfrei unter Einhaltung der a.a.R.d.T..",
    ["SIGA Contact Person's Email"]: "E-mail SIGA Ansprechpartner",
    ["This configuration is not available"]:
      "Diese Konfiguration ist nicht verfügbar",
    ["This construction is not physically possible"]:
      "Diese Konstruktion ist bauphysikalisch nicht möglich",
    ["From which side is the vapor barrier / airtightness membrane installed?"]:
      "Von welcher Seite wird die Dampfbremse / Luftdichtheitsbahn montiert?",
    ["From the inside"]: "Von innen",
    ["From the outside"]: "Von aussen",
    ["Open to diffusion with/without installation underlay"]:
      "Diffusionsoffen  mit / ohne Verlegeunterlage",
    ["Diffusion-tight with wood layer (Sd >20 m)"]:
      "Diffusionsdicht mit Holzschicht (Sd >20 m)",
    ["Diffusion-open with wood layer"]: "Diffusionsoffen mit Holzschicht",
    ["Type of insulation material between the rafters (full rafter insulation):"]:
      "Dämmstofftyp zwischen den Sparren (Vollsparrendämmung):",
    ["What material properties does the intermediate rafter insulation have?"]:
      "Welche Materialeigenschaften hat die Zwischensparrendämmung?",
    ["What type of sealing is above the wooden formwork?"]:
      "Welcher Typ Abdichtung ist oberhalb der Holzschalung?",
    ["Diffusible undercover"]: "Diffusionsoffenen Unterdeckung",
    ["Which covering material is used?"]:
      "Welches Eindeckungsmaterial wird verwendet?",
    ["Brick/concrete roof tiles"]: "Ziegel / Betondachstein",
    ["Metal roofing"]: "Metalleindeckung",
    ["Slate"]: "Schiefer / Zementfaserplatten",
    ["No additional covering (bare roof)"]:
      "Keine zusatliche Uberdeckung (Nacktdach)",
    ["Bitumen membrane / shingles"]: "Bitumenbahn / Schindeln",
    ["Color of the covering?"]: "Farbe der Eindeckung?",
    ["Buildings / Trees"]: "Gebäude / Bäume",
    ["Alignment (worst situation)"]: "Ausrichtung (schlechteste Situation)",
    ["Roof pitch"]: "Dachneigung",
    ["North"]: "Nord",
    ["East"]: "Ost",
    ["South"]: "Süd",
    ["West"]: "West",
    ["Roof insulation with vapor barrier"]: "Aufdachdämmung mit Dampfbremse",
    ["Roof insulation with Majcoat 200 (SOB)"]:
      "Aufdachdämmung mit Majcoat 200 (SOB)",
    ["Roof insulation with sub-top variant"]:
      "Aufdachdämmung mit Sub-Top Variante",
    ["Type of insulation over the rafters"]: "Dämmstofftyp über den Sparren",
    ["What material properties does on-roof insulation have?"]:
      "Welche Materialeigenschaften hat die Aufdachdaemmung?",
    ["Should an additional underlayment be applied above the roof insulation?"]:
      "Soll eine zusäztliche Unterdeckbahn oberhalb der Aufdachdämmung aufgebracht werden?",
    ["Which underlay should be installed?"]:
      "Welche Unterdeckbahn soll montiert werden?",
    ["Majcoat 200 / Majcoat 250"]: "Majcoat 200 (SOB) / Majcoat 250 (SOB)",
    ["Majcoat 350"]: "Majcoat 350",
    ["Which ventilated roofing material is used?"]:
      "Welches hinterlüftete Eindeckungsmaterial wird verwendet?",
    ["PV - in-roof system"]: "PV - Indachsystem",
    ["Underlay membrane sd value ≤ 0.1 m"]: "Unterdeckbahn sd-Wert ≤ 0,1 m",
    ["There must be no additional airtight layer between the vapor barrier and the insulation between the rafters on the construction side."]:
      "Es darf sich keine zusätzliche luftdichte Schicht zwischen der Dampfbremse und Zwischensparrendämmung konstruktionsseitig befinden.\n",
    ["Majcoat 200 (SOB)"]: "Majcoat 200 (SOB)",
    ["Detect-free under the following conditions.\n{link}SIGA tracks in various constructions{linkEnd}"]:
      "Nachweisfrei unter den folgenden Bedingungen.\n{link}SIGA Bahnen in verschiedenen Konstruktionen{linkEnd}",
    ["PUR / PIR / PH"]: "PUR / PIR / PH",
    ["Shingles"]: "Bitumenschindeln",
    ["Majpell 35 SOB"]: "Majpell 35 SOB",
    ["The vapor barrier membranes Majrex 200 and Majpell 5 / Majpell 5 R are not suitable for use as temporary covering and must be built over as soon as possible. Majpell 35 SOB can be exposed to the elements for a period of up to 4 weeks."]:
      "Die Dampfbremsbahnen Majrex 200 und Majpell 5 / Majpell 5 R sind nicht für den Einsatz als Behelsdeckung geeignet und müssen zeitnah überbaut werden. Majpell 35 SOB ist für einen Zeitraum von bis zu 4 Wochen freibewitterbar.",
    ["Should Wetguard 200 SA be installed as construction protection?"]:
      "Soll Wetguard 200 SA als Bauzeitenschutz montiert werden?",
    ["Attention: Does not replace the first waterproofing layer / vapor barrier in the construction!"]:
      "Achtung: Ersetzt nicht die erste Abdichtungslage / Dampfsperre in der Konstruktion!",
    ["Only letters, spaces, and hyphens are allowed"]:
      "Nur Buchstaben, Leerzeichen und Bindestriche sind erlaubt",
  },
};
