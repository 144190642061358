import { angleNodes, notSupportedMessage } from "../../constants";
import { API, BUTTON_TYPE, Condition, SECTION_ID } from "../../types";
import pitchedRoof_majcoat from "../../../assets/pitchedRoof_majcoat.svg";

export const pitchedRoofOpenMajcoatSections = [
  {
    id: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE,
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    // sequential: true,
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
      nodeActive: false,
    },
    nextSection: SECTION_ID.FORM,
    nodes: angleNodes.map((node) => {
      if (node.id === "pr_11_2") {
        return {
          ...node,
          disabled: false,
        };
      }
      return node;
    }),
  },
  {
    id: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_OUTSIDE,
    title:
      "Type of insulation material between the rafters (full rafter insulation):",
    divider: true,
    nr: 1,
    highlightNode: {
      top: "165px",
      left: "",
      width: "100%",
      height: "110px",
    },
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "1",
      top: "205px",
      wrapperLeft: "0%",
      left: "360px",
      imgTop: "5%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_5_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
      },
      {
        id: "pr_5_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
      },
      {
        id: "pr_5_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
      },
      { id: "pr_5_4", title: "Other", value: "Other", end: true },
      {
        id: "pr_5_6",
        title: "Hemp / sheep wool",
        value: "Hemp / sheep wool",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_5_4",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
    title:
      "What material properties does the intermediate rafter insulation have?",
    highlightNode: {
      top: "165px",
      left: "",
      width: "100%",
      height: "110px",
    },
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "1",
      top: "205px",
      wrapperLeft: "0%",
      left: "360px",
      imgTop: "5%",
      width: "100%",
    },
    multipleChoice: true,
    mustSelectAll: true,
    sequential: true,
    divider: true,
    nextSection: SECTION_ID.PITCHED_ROOF_WOODEN_SUPPORT_PANEL_OUTSIDE,
    nodes: [
      {
        id: "pr_6_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_6_1_1",
            title: "120 - 139",
            value: "120 - 139",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_2",
            title: "140 - 159",
            value: "140 - 159",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_3",
            title: "160 - 179",
            value: "160 - 179",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_4",
            title: "180 - 199",
            value: "180 - 199",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_5",
            title: "200 - 219",
            value: "200 - 219",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_6",
            title: "220 - 239",
            value: "220 - 239",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_7",
            title: "240 - 259",
            value: "240 - 259",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_8",
            title: "260 - 279",
            value: "260 - 279",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_9",
            title: "280 - 299",
            value: "280 - 299",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_10",
            title: "300 - 320",
            value: "300 - 320",
            parent: "pr_6_1",
          },
          // {
          //   id: "pr_6_1_11",
          //   title: "≥ 320",
          //   value: "≥ 320",
          //   parent: "pr_6_1",
          // },
          {
            id: "pr_6_1_12",
            title: "other",
            value: "other",
            parent: "pr_6_1",
            end: true,
          },
        ],
      },
      {
        id: "pr_6_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_6_2_1",
            title: "0.041 - 0.045",
            value: "0.045",
            parent: "pr_6_2",
          },
          {
            id: "pr_6_2_2",
            title: "0.035 - 0.040",
            value: "0.040",
            parent: "pr_6_2",
          },
          {
            id: "pr_6_2_3",
            title: "0.032 - 0.034",
            value: "0.034",
            parent: "pr_6_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_6_1_12",
      },
    ],
  },
  {
    title: "Is there a wooden support panel for the over-insulation?",
    id: SECTION_ID.PITCHED_ROOF_WOODEN_SUPPORT_PANEL_OUTSIDE,
    nr: 2,
    divider: true,
    highlightNode: {
      top: "160px",
      left: "",
      width: "100%",
      height: "15px",
    },
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "2",
      top: "150px",
      wrapperLeft: "0%",
      left: "140px",
      imgTop: "5%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_7_1",
        title: "Yes",
        value: "Yes",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_OUTSIDE,
      },
      {
        id: "pr_7_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_OUTSIDE,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_OUTSIDE,
    title: "Type of insulation over the rafters",
    divider: true,
    nr: 3,
    highlightNode: {
      top: "86px",
      left: "",
      width: "100%",
      height: "75px",
    },
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
    },
    nodes: [
      // {
      //   id: "pr_9_1",
      //   title: "Cellulose",
      //   value: "Cellulose",
      //   nextSection: SECTION_ID.PITCHED_ROOF_ADDITIONAL_UNDERLAYMENT_OUTSIDE,
      // },
      {
        id: "pr_9_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection: SECTION_ID.PITCHED_ROOF_ADDITIONAL_UNDERLAYMENT_OUTSIDE,
      },
      {
        id: "pr_9_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection: SECTION_ID.PITCHED_ROOF_ADDITIONAL_UNDERLAYMENT_OUTSIDE,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
    title: "What material properties does on-roof insulation have?",
    api: API.PITCHED_ROOF_OUTSIDE_MAJCOAT,
    paramsFetched: false,
    highlightNode: {
      top: "86px",
      left: "",
      width: "100%",
      height: "75px",
    },
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
    },
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    sequential: true,
    nextSection: SECTION_ID.PITCHED_ROOF_VENTILATED_ROOFING_MATERIAL,
    nodes: [
      {
        id: "pr_10_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        shouldShowDisabledMessage: true,
        childNodes: [
          {
            id: "pr_10_1_1",
            title: "52",
            value: "52",
            parent: "pr_10_1",
          },
          {
            id: "pr_10_1_2",
            title: "60",
            value: "60",
            parent: "pr_10_1",
          },
          {
            id: "pr_10_1_3",
            title: "80",
            value: "80",
            parent: "pr_10_1",
          },
          {
            id: "pr_10_1_4",
            title: "100",
            value: "100",
            parent: "pr_10_1",
          },
          {
            id: "pr_10_1_5",
            title: "120",
            value: "120",
            parent: "pr_10_1",
          },
          {
            id: "pr_10_1_6",
            title: "140",
            value: "140",
            parent: "pr_10_1",
          },
          {
            id: "pr_10_1_7",
            title: "160",
            value: "160",
            parent: "pr_10_1",
          },
          // {
          //   id: "pr_10_1_8",
          //   title: "180",
          //   value: "180",
          //   parent: "pr_10_1",
          // },
        ],
      },
      {
        id: "pr_10_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        disabled: true,
        shouldShowDisabledMessage: true,
        childNodes: [
          {
            id: "pr_10_2_1",
            title: "0.044 - 0.047",
            value: "0.044 - 0.047",
            parent: "pr_10_2",
          },
          {
            id: "pr_10_2_2",
            title: "0.041 - 0.043",
            value: "0.041 - 0.043",
            parent: "pr_10_2",
          },
          {
            id: "pr_10_2_3",
            title: "0.036 - 0.040",
            value: "0.036 - 0.040",
            parent: "pr_10_2",
          },
          {
            id: "pr_10_2_4",
            title: "0.031 - 0.035",
            value: "0.031 - 0.035",
            parent: "pr_10_2",
          },
        ],
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_ADDITIONAL_UNDERLAYMENT_OUTSIDE,
    title:
      "Should an additional underlayment be applied above the roof insulation?",
    divider: false,
    highlightNode: {
      top: "86px",
      left: "",
      width: "100%",
      height: "75px",
    },
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_11_1",
        title: "Yes",
        value: "Yes",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE,
      },
      {
        id: "pr_11_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE,
    title: "Which underlay should be installed?",
    highlightNode: {
      top: "86px",
      left: "",
      width: "100%",
      height: "75px",
    },
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_12_1",
        title: "Majcoat 200 / Majcoat 250",
        value: "Majcoat 200 / Majcoat 250",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
      },
      {
        id: "pr_12_2",
        title: "Majcoat 350",
        value: "Majcoat 350",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
      },
      {
        id: "pr_12_3",
        title: "Underlay membrane sd value ≤ 0.1 m",
        value: "Underlay membrane sd value ≤ 0.1 m",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_OUTSIDE,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_VENTILATED_ROOFING_MATERIAL,
    title: "Which ventilated roofing material is used?",
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_13_1",
        title: "Brick/concrete roof tiles",
        value: "Brick/concrete roof tiles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE,
                nodeId: "pr_11_2_1",
              },
            ],
          },
        ],
      },
      {
        id: "pr_13_2",
        title: "Metal roofing",
        value: "Metal roofing",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
      },
      {
        id: "pr_13_3",
        title: "Slate",
        value: "Slate",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE,
                nodeId: "pr_11_2_1",
              },
            ],
          },
        ],
      },
      {
        id: "pr_13_4",
        title: "PV - in-roof system",
        value: "PV - in-roof system",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE,
                nodeId: "pr_11_2_1",
              },
            ],
          },
        ],
      },
      {
        id: "pr_13_5",
        title: "Other",
        value: "Other",
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_13_5",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
    title: "Color of the covering?",
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_14_1",
        title: "Dark (Black, anthracite)",
        value: "Dark (Black, anthracite)",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_OUTSIDE,
      },
      {
        id: "pr_14_2",
        title: "Light (White, beige)",
        value: "Light (White, beige)",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_OUTSIDE,
      },
      {
        id: "pr_14_3",
        title: "Green, red & brown",
        value: "Green, red & brown",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_OUTSIDE,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_SHADE_IS_CAUSED_BY_OUTSIDE,
    title: "Shade is caused by",
    visualNode: {
      img: pitchedRoof_majcoat,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_10_1",
        title: "Solar panel",
        value: "Solar panel",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE,
      },
      {
        id: "pr_10_2",
        title: "Buildings / Trees",
        value: "Buildings / Trees",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE,
      },
      {
        id: "pr_10_3",
        title: "No shade",
        value: "No shade",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT_OUTSIDE,
      },
    ],
  },
];
