import { Condition, DataNode, DataSection } from "../data/types";

export const isNodeDisabled = (b: DataNode, allData: DataSection[]) =>
  b.disabled ||
  (!!b.disableRules &&
    b.disableRules.some((r) => {
      if (r.condition === Condition.AND) {
        return r.rules.every(
          (rr) =>
            !!allData
              .find((e) => e.id === rr?.sectionId)
              ?.nodes.find(
                (n) =>
                  (n.id === rr?.nodeId && n.selected) ||
                  (n.value === rr?.nodeId && n.selected)
              )
        );
      } else if (r.condition === Condition.OR) {
        return r.rules.some(
          (rr) =>
            !!allData
              .find((e) => e.id === rr?.sectionId)
              ?.nodes.find(
                (n) =>
                  (n.id === rr?.nodeId && n.selected) ||
                  (n.value === rr?.nodeId && n.selected)
              )
        );
      } else {
        return false;
      }
    }));
