import sigaRoof from "../../assets/sigaRoof.svg";

import coldRoof_new from "../../assets/coldRoof_new.svg";
import flatRoof_new from "../../assets/flatRoof_new.svg";

import coldRoofPng_new from "../../assets/images/coldRoof_new.png";
import flatRoofPng_new from "../../assets/images/flatRoof_new.png";

import notVentilated from "../../assets/notVentilated.svg";
import notVentilatedPng from "../../assets/images/notVentilated.png";

import vcl from "../../assets/images/vcl.svg";
import { API, BUTTON_TYPE, DataSection, SECTION_ID } from "../types";
import {
  customPdfForm,
  notSupportedMessage,
  roofNotSupportedNewMessage,
} from "../constants";

export const flatRoofSections: DataSection[] = [
  {
    id: SECTION_ID.FLAT_ROOF_BUILD_UP,
    divider: true,
    title: "Roof build-up:",
    nodes: [
      // {
      //   id: "4_1",
      //   title: "Warm roof with Insulation above the deck only",
      //   value: "Warm roof with Insulation above the deck only",
      //   buttonType: BUTTON_TYPE.VISUAL,
      //   icon: warmRoof,
      //   iconPng: warmRoofPng,
      //   disabled: true,
      // },
      {
        id: "4_3",
        title: "Flat roof with insulation above and below the deck",
        value: "Flat roof with insulation above and below the deck",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: flatRoof_new,
        iconPng: flatRoofPng_new,
        nextSection: SECTION_ID.INSULATION_BETWEEN_RAFTERS,
      },
      {
        id: "4_2",
        title: "Cold roof with active ventilation",
        value: "Cold roof with active ventilation",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: coldRoof_new,
        iconPng: coldRoofPng_new,
        end: true,
      },
      {
        id: "4_1",
        title: "Not ventilated with on-roof insulation",
        value: "Not ventilated with on-roof insulation",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: notVentilated,
        iconPng: notVentilatedPng,
        end: true,
      },
    ],
    warning: [
      {
        message: roofNotSupportedNewMessage,
        rule: "4_2",
        pdf: customPdfForm,
      },
      {
        message: roofNotSupportedNewMessage,
        rule: "4_1",
        pdf: customPdfForm,
      },
    ],
  },
  {
    id: SECTION_ID.VAPOR_CONTROL_LAYER,
    title: "Which vapour control layer (VCL) do you want to use?",
    nr: 4,
    divider: true,
    visualNode: {
      img: sigaRoof,
      label: "4",
      top: "155px",
      left: "-45px",
    },
    highlightNode: {
      top: "165px",
      left: "0px",
      width: "100%",
      height: "11px",
    },
    coverImage: vcl,
    nodes: [
      {
        id: "1_2",
        title: "Majrex",
        value: "Majrex",
        nextSection: SECTION_ID.EXTERNAL_OVER_INSULATION,
      },
      {
        id: "1_1",
        title: "Majpell 5 / Majpell 5 R",
        value: "Majpell 5 / Majpell 5 R",
        nextSection: SECTION_ID.EXTERNAL_OVER_INSULATION,
      },
    ],
    warning: [
      {
        message:
          "In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer.",
        rule: "1_1",
      },
    ],
  },
  {
    id: SECTION_ID.EXTERNAL_OVER_INSULATION,
    title: "External over-insulation (bio-based insulation is excluded):",
    nr: 5,
    api: API.FLAT_ROOF,
    paramsFetched: false,
    visualNode: {
      img: sigaRoof,
      label: "5",
      top: "0px",
      left: "-40px",
    },
    highlightNode: {
      top: "6px",
      left: "",
      width: "100%",
      height: "20px",
    },
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    sequential: true,
    nextSection: SECTION_ID.FORM,
    nodes: [
      {
        id: "9_1",
        title: "Minimum insulation thickness in the layer (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        shouldShowDisabledMessage: true,
        childNodes: [
          { id: "9_1_1", value: "40mm", title: "40mm", parent: "9_1" },
          { id: "9_1_2", value: "60 mm", title: "60 mm", parent: "9_1" },
          {
            id: "9_1_3",
            value: "80 mm",
            title: "80 mm",
            parent: "9_1",
          },
          { id: "9_1_4", value: "100mm", title: "100mm", parent: "9_1" },
          { id: "9_1_5", value: "120", title: "120mm", parent: "9_1" },
          { id: "9_1_6", value: ">120", title: ">120", parent: "9_1" },
        ],
      },
      {
        id: "9_2",
        title: "Lambda value λ(w/mK):",
        disabled: true,
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        shouldShowDisabledMessage: true,
        childNodes: [
          {
            id: "9_2_1",
            title: "0.036 - 0.040",
            value: "0.036 - 0.040",
            parent: "9_2",
          },
          {
            id: "9_2_2",
            title: "0.031 - 0.035",
            value: "0.031 - 0.035",
            parent: "9_2",
          },
          {
            id: "9_2_3",
            title: "0.026 - 0.030",
            value: "0.026 - 0.030",
            parent: "9_2",
          },
          {
            id: "9_2_4",
            title: "0.025 or less",
            value: "0.025 or less",
            parent: "9_2",
          },
        ],
      },
    ],
  },
  {
    id: SECTION_ID.INSULATION_BETWEEN_RAFTERS,
    nr: 1,
    visualNode: {
      img: sigaRoof,
      label: "1",
      top: "90px",
      left: "-40px",
    },
    highlightNode: {
      top: "47px",
      left: "",
      width: "100%",
      height: "118px",
    },
    divider: true,
    title: "Type of the insulation between the rafters",
    nodes: [
      {
        id: "5_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection: SECTION_ID.INSULATION_LAYER_THICKNESS,
      },
      {
        id: "5_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection: SECTION_ID.INSULATION_LAYER_THICKNESS,
      },
      {
        id: "5_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection: SECTION_ID.INSULATION_LAYER_THICKNESS,
      },
      { id: "5_5", title: "Other", value: "Other", end: true },
      {
        id: "5_6",
        title: "Hemp / sheep wool",
        value: "Hemp / sheep wool",
        nextSection: SECTION_ID.INSULATION_LAYER_THICKNESS,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "5_5",
      },
    ],
  },
  {
    id: SECTION_ID.INSULATION_LAYER_THICKNESS,
    nextSection: SECTION_ID.WOODEN_SUPPORT_PANEL,
    highlightNode: {
      top: "47px",
      left: "",
      width: "100%",
      height: "118px",
    },
    divider: true,
    // nr: 1,
    visualNode: {
      img: sigaRoof,
      label: "1",
      top: "90px",
      left: "-40px",
    },
    multipleChoice: true,
    mustSelectAll: true,
    nodes: [
      {
        id: "6_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "6_1_1",
            title: "120 - 139",
            value: "120 - 139",
            parent: "6_1",
          },
          {
            id: "6_1_2",
            title: "140 - 159",
            value: "140 - 159",
            parent: "6_1",
          },
          {
            id: "6_1_3",
            title: "160 - 179",
            value: "160 - 179",
            parent: "6_1",
          },
          {
            id: "6_1_4",
            title: "180 - 199",
            value: "180 - 199",
            parent: "6_1",
          },
          {
            id: "6_1_5",
            title: "200 - 219",
            value: "200 - 219",
            parent: "6_1",
          },
          {
            id: "6_1_6",
            title: "220 - 239",
            value: "220 - 239",
            parent: "6_1",
          },
          {
            id: "6_1_7",
            title: "240 - 259",
            value: "240 - 259",
            parent: "6_1",
          },
          {
            id: "6_1_8",
            title: "260 - 279",
            value: "260 - 279",
            parent: "6_1",
          },
          {
            id: "6_1_9",
            title: "280 - 300",
            value: "280 - 300",
            parent: "6_1",
          },
          {
            id: "6_1_10",
            title: "other",
            value: "other",
            parent: "6_1",
            end: true,
          },
        ],
      },
      {
        id: "6_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "6_2_1",
            title: "0.041 - 0.045",
            value: "0.045",
            parent: "6_2",
          },
          {
            id: "6_2_2",
            title: "0.036 - 0.040",
            value: "0.040",
            parent: "6_2",
          },
          {
            id: "6_2_3",
            title: "0.031 - 0.035",
            value: "0.035",
            parent: "6_2",
          },
          {
            id: "6_2_4",
            title: "0.025 - 0.030",
            value: "0.032",
            parent: "6_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "6_1_10",
      },
    ],
  },
  {
    title: "Is there a wooden support panel for the over-insulation?",
    id: SECTION_ID.WOODEN_SUPPORT_PANEL,
    warning: [
      {
        message:
          "Important: We recomend to install Bitum membran (SD value bigger than 100m) on the to protect wood susbrstate membrane.",
        rule: "7_1",
        includeInLetter: true,
      },
      {
        message: notSupportedMessage,
        rule: "7_2",
      },
    ],
    divider: true,
    nr: 2,
    visualNode: {
      img: sigaRoof,
      label: "2",
      top: "25px",
      left: "calc(100% - 40px)",
    },
    highlightNode: {
      top: "34px",
      left: "",
      width: "100%",
      height: "14px",
    },
    nodes: [
      {
        id: "7_1",
        title: "Yes",
        value: "Yes",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.WOOD_SUBSTRATE,
      },
      {
        id: "7_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        end: true,
      },
    ],
  },
  {
    id: SECTION_ID.WOOD_SUBSTRATE,
    title: "What is the type of wood substrate?",
    highlightNode: {
      top: "34px",
      left: "",
      width: "100%",
      height: "14px",
    },
    visualNode: {
      img: sigaRoof,
      label: "2",
      top: "25px",
      left: "calc(100% - 40px)",
    },
    nodes: [
      {
        id: "8_1",
        title: "OSB",
        value: "OSB",
        nextSection: SECTION_ID.WETGUARD,
      },
      {
        id: "8_2",
        title: "Wooden formwork",
        value: "Wooden formwork",
        nextSection: SECTION_ID.WETGUARD,
      },
      {
        id: "8_3",
        title: "3 layer plate",
        value: "3 layer plate",
        nextSection: SECTION_ID.WETGUARD,
      },
      // {
      //   id: "8_4",
      //   title: "Other",
      //   value: "Other",
      //   buttonType: BUTTON_TYPE.RADIO,
      //   freeType: true,
      // },
    ],
    divider: true,
  },
  {
    id: SECTION_ID.WETGUARD,
    highlightNode: {
      top: "34px",
      left: "",
      width: "100%",
      height: "14px",
    },
    visualNode: {
      img: sigaRoof,
      label: "2",
      top: "25px",
      left: "calc(100% - 40px)",
    },
    title: "Should Wetguard 200 SA be installed as construction protection?",
    nodes: [
      {
        id: "13_1",
        title: "Yes",
        value: "Yes",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.WATERPROOFING,
      },
      {
        id: "13_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.WATERPROOFING,
      },
    ],
    warning: [
      {
        message:
          "Attention: Does not replace the first waterproofing layer / vapor barrier in the construction!",
        rule: "13_1",
        includeInLetter: true,
      },
    ],
  },
  {
    id: SECTION_ID.WATERPROOFING,
    title: "What is the type of waterproofing?",
    nodes: [
      {
        id: "8_1_1",
        title: "EPDM",
        value: "EPDM",
        nextSection: SECTION_ID.COLOUR_WATERPROOFING,
      },
      {
        id: "8_1_2",
        title: "PVC / EVA",
        value: "PVC / EVA",
        nextSection: SECTION_ID.COLOUR_WATERPROOFING,
      },
      {
        id: "8_1_3",
        title: "Bitumen",
        value: "Bitumen",
        nextSection: SECTION_ID.COLOUR_WATERPROOFING,
      },
    ],
    nr: 3,
    divider: true,
    visualNode: {
      img: sigaRoof,
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
  },
  {
    id: SECTION_ID.COLOUR_WATERPROOFING,
    title: "Colour of the waterproofing membrane:",
    nr: 3,
    divider: true,
    visualNode: {
      img: sigaRoof,
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
    nodes: [
      {
        id: "'10_1",
        title: "Dark (Black, anthracite)",
        value: "Dark (Black, anthracite)",
        nextSection: SECTION_ID.SHADE,
      },
      {
        id: "'10_2",
        title: "Middle (Grey)",
        value: "Middle (Grey)",
        nextSection: SECTION_ID.SHADE,
      },
      {
        id: "'10_3",
        title: "Light (White, beige)",
        value: "Light (White, beige)",
        nextSection: SECTION_ID.SHADE,
      },
      {
        id: "'10_4",
        title: "Green, red & brown",
        value: "Green, red & brown",
        nextSection: SECTION_ID.SHADE,
      },
    ],
  },
  {
    id: SECTION_ID.SHADE,
    title: "Shade is caused by",
    visualNode: {
      img: sigaRoof,
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
    divider: false,
    nodes: [
      {
        id: "12_1",
        title: "Solar panel",
        value: "Solar panel",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_2",
        title: "Terrace (wood or stone)",
        value: "Terrace (wood or stone)",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_3",
        title: "Vegetation (0 - 100 mm)",
        value: "Vegetation (0 - 100 mm)",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_4",
        title: "Pebbles (max. 120 mm)",
        value: "Pebbles (max. 120 mm)",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_5",
        title: "Building",
        value: "Building",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_6",
        title: "No shade",
        value: "No shade",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
    ],
  },
];
